import apiTool from "./index";
import ApiService from "./ApiService";

// Campaigns
const GET_SPECIFIC_CAMPAIGN = "api/v1/emailing/campaign/detail/<pk>";
const LIST_CAMPAIGNS = "api/v1/emailing/campaign/list/";
const DELETE_CAMPAIGN = "api/v1/cemailing/campaign/delete/<pk>/";
const ADD_CAMPAIGN = "api/v1/emailing/campaign/create/";
const UPDATE_CAMPAIGN = "api/v1/emailing/campaign/update/<pk>/";

const FORCE_SEND = "api/v1/emailing/campaign/send/<pk>"

// Segments
const GET_SPECIFIC_SEGMENT = "api/v1/emailing/segment/detail/<pk>";
const LIST_SEGMENTS = "api/v1/emailing/segment/list/";
const DELETE_SEGMENT = "api/v1/emailing/segment/delete/<pk>/";
const ADD_SEGMENT = "api/v1/emailing/segment/create/";
const UPDATE_SEGMENT = "api/v1/emailing/segment/update/<pk>/";

export default {
    getCampaign(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_CAMPAIGN.replace("<pk>", id)).then((response) => {
            return response.data;
        });
    },
    listCampaigns(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_CAMPAIGNS, payload).then((response) => {
            return response.data;
        });
    },
    addCampaign(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_CAMPAIGN, payload).then((response) => {
            return response.data;
        });
    },
    deleteCampaign(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_CAMPAIGN.replace("<pk>", id)).then((response) => {
            return response.data;
        });
    },
    updateCampaign(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_CAMPAIGN.replace("<pk>", id), payload).then((response) => {
            return response.data;
        });
    },
    forceSend(id) {
        ApiService.setHeader();
        return ApiService.get(FORCE_SEND.replace("<pk>", id)).then((response) => {
            return response.data;
        });
    },

    // --------------------------------------------------------------------------------------------------------------------

    listSegments(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_SEGMENTS, payload).then((response) => {
            return response.data;
        });
    },
    getSegment(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_SEGMENT.replace("<pk>", id)).then((response) => {
            return response.data;
        });
    },
    addSegment(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_SEGMENT, payload).then((response) => {
            return response.data;
        });
    },
    deleteSegment(id) {
        ApiService.setHeader();
        return ApiService.delete(DELETE_SEGMENT.replace("<pk>", id)).then((response) => {
            return response.data;
        });
    },
    updateSegment(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_SEGMENT.replace("<pk>", id), payload).then((response) => {
            return response.data;
        });
    },
};
