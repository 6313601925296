
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import membersApi from "@/core/services/MembersApi";
import emailingApi from "@/core/services/EmailingApi";
import FilterTags from "./crafted/widgets/FilterTags.vue";
import { Form, Field } from "vee-validate";
import { object, string } from "yup";

export default defineComponent({
    name: "SegmentDetails",
    components: { FilterTags, Form, Field },
    data() {
        return {
            tagsKey: 0,
            filtersPayload: {},
            viewing: false,
            currentPage: 1,
            totalPages: 0,
            actualFilter: "",
            actualCol: "",
            segment: {} as any,
            loading: true,
            title: "" as any,
            perm_pos: "",
            activeFilters: [] as any,
            reachedMembers: "",
            payload: {} as any,
            newSegment: {
                title: "",
                filters: "",
            },
            filters: {
                period: {
                    options: [
                        {
                            id: 0,
                            label: "After",
                            value: "after",
                        },
                        {
                            id: 1,
                            label: "At",
                            value: "at",
                        },
                        {
                            id: 2,
                            label: "Before",
                            value: "before",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_period: "at",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                grade: {
                    options: [] as any,
                    grades: [] as any,
                    grade_id: [],
                },
                bailliage: {
                    nat_options: [] as any,
                    prov_options: [] as any,
                    reg_options: [] as any,
                    national: [],
                    national_id: [],
                    provincial: [],
                    provincial_id: [],
                    regional: [],
                    regional_id: [],
                },
                age: {
                    options: [
                        {
                            id: 0,
                            label: "Equal to",
                            value: "equal to",
                        },
                        {
                            id: 1,
                            label: "More than",
                            value: "more than",
                        },
                        {
                            id: 2,
                            label: "Less than",
                            value: "less than",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_age: "equal to",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                memberTypes: {
                    options: [
                        {
                            id: 0,
                            label: "Amateur",
                            value: "amateur",
                        },
                        {
                            id: 1,
                            label: "Pro",
                            value: "pro",
                        },
                        {
                            id: 2,
                            label: "OMGD",
                            value: "omgd",
                        },
                        {
                            id: 3,
                            label: "Jeune sommelier",
                            value: "sommelier",
                        },
                        {
                            id: 4,
                            label: "Jeune chef rôtisseur",
                            value: "chef",
                        },
                    ],
                    selectedMemberTypes: [],
                    is_amateur: false,
                    is_pro: false,
                    is_omgd: false,
                    is_young_clerk: false,
                    is_young_sommelier: false,
                },
                sex: "",
                dues: "" as any,
            },
        };
    },
    setup() {
        const router = useRouter();
        const route = useRoute();

        const segmentSchema = object().shape({
            title: string().required().label("Segment title"),
        });

        const backToList = () => {
            router.push({ name: "segments" });
        };

        return {
            backToList,
            segmentSchema,
            route,
            router,
        };
    },
    mounted() {
        this.title = document.querySelector("#title-breadcrumbs h1") as HTMLElement;
        if (this.title) this.title.style.cssText = "display: none!important;";

        if (this.route.params.segment_id) {
            this.viewing = true;
            this.getSegment(this.$route.params.segment_id);
            setCurrentPageBreadcrumbs("Segment #" + this.route.params.segment_id, [
                {
                    link: "/emailing/segments",
                    label: "Segments",
                },
            ]);
        } else {
            this.loading = false;
            setCurrentPageBreadcrumbs("New Segment", [
                {
                    link: "/emailing/segments",
                    label: "Segments",
                },
            ]);
        }
        this.getFields();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    beforeUnmount() {
        this.title.style.cssText = "display: flex !important;";
    },
    methods: {
        addSegment(e: any) {
            var payload = {};

            if (!this.viewing) {
                payload = {
                    title: e.title,
                    structure: this.payload,
                    tags: this.activeFilters,
                };

                if (this.activeFilters.length === 0) {
                    ElMessage({
                        type: "error",
                        message: "Please select at least one filter.",
                    });
                } else {
                    emailingApi.addSegment(payload).then((res) => {
                        if (res.success) {
                            ElMessage({
                                type: "success",
                                message: "Segment added",
                            });
                            this.router.push({ name: "segments" });
                        } else {
                            ElMessage({
                                type: "error",
                                message: "An error occurred",
                            });
                        }
                    });
                }
            } else {
                payload = {
                    title: e.title,
                };

                emailingApi.updateSegment(this.route.params.segment_id, payload).then((res: any) => {
                    if (res.success) {
                        ElMessage({
                            type: "success",
                            message: "Segment updated",
                        });
                    } else {
                        ElMessage({
                            type: "error",
                            message: "An error occurred",
                        });
                    }
                });
            }
        },
        getSegment(id: any) {
            this.loading = true;

            emailingApi.getSegment(id).then((response) => {
                this.segment = response.data.segment;
                this.newSegment.title = this.segment.title;
                this.reachedMembers = this.segment.reached;
                this.activeFilters = this.segment.tags;
                this.tagsKey += 2;
                this.loading = false;
            });
        },
        getFields() {
            // Get bailliages
            membersApi.getBailliages().then((res: any) => {
                res.data.map((bailliage: any) => {
                    if (bailliage.type === "National" || bailliage.type === "national") this.filters.bailliage.nat_options.push(bailliage);
                    else if (bailliage.type === "Provincial" || bailliage.type === "provincial") this.filters.bailliage.prov_options.push(bailliage);
                    else if (bailliage.type === "Regional" || bailliage.type === "regional") this.filters.bailliage.reg_options.push(bailliage);
                });
                this.filters.bailliage.nat_options.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                this.filters.bailliage.prov_options.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                this.filters.bailliage.reg_options.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
            });

            // Get grades
            membersApi
                .getGrades({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.grade.options = res.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
        },
        applyFilters() {
            // Reset the filters
            this.activeFilters = [];
            this.payload = {};

            // Period filter
            if (this.filters.period.selected_value_1) {
                var periodLabel =
                    this.filters.period.selected_period === "between"
                        ? "Period is between <b>" + this.filters.period.selected_value_1 + "</b> and <b>" + this.filters.period.selected_value_2 + "</b>"
                        : "Period is " + this.filters.period.selected_period + " <b>" + this.filters.period.selected_value_1 + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: periodLabel,
                    key: "period",
                    values: {
                        type: this.filters.period.selected_period,
                        values:
                            this.filters.period.selected_period === "between"
                                ? [this.filters.period.selected_value_1, this.filters.period.selected_value_2]
                                : [this.filters.period.selected_value_1],
                    },
                });
            }

            // Gender filter
            if (this.filters.sex) {
                var genderLabel = this.filters.sex === "M" ? "Gender is <b>Male</b>" : "Gender is <b>Female</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: genderLabel,
                    key: "gender",
                    values: [this.filters.sex],
                });
            }

            // Age filter
            if (this.filters.age.selected_value_1) {
                var ageLabel =
                    this.filters.age.selected_age === "between"
                        ? "Age is between <b>" +
                          this.filters.age.selected_value_1 +
                          " years old </b> and <b>" +
                          this.filters.age.selected_value_2 +
                          " years old</b>"
                        : "Age is " + this.filters.age.selected_age + " <b>" + this.filters.age.selected_value_1 + " years old</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: ageLabel,
                    key: "age",
                    values: {
                        type: this.filters.age.selected_age,
                        values:
                            this.filters.age.selected_age === "between"
                                ? [this.filters.age.selected_value_1, this.filters.age.selected_value_2]
                                : [this.filters.age.selected_value_1],
                    },
                });
            }

            // Bailliages filter
            // National
            if (this.filters.bailliage.national.length > 0) {
                var natBailliageLabel =
                    this.filters.bailliage.national.length > 1
                        ? "Multiple national bailliages selected"
                        : "National bailliage is <b>" + this.filters.bailliage.national[0] + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: natBailliageLabel,
                    key: "national_bailliage",
                    values: [this.filters.bailliage.national_id],
                });
            }

            // Provincial
            if (this.filters.bailliage.provincial.length > 0) {
                var provBailliageLabel =
                    this.filters.bailliage.provincial.length > 1
                        ? "Multiple provincial bailliages selected"
                        : "Provincial bailliage is <b>" + this.filters.bailliage.provincial[0] + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: provBailliageLabel,
                    key: "provincial_bailliage",
                    values: [this.filters.bailliage.provincial_id],
                });
            }

            // Regional
            if (this.filters.bailliage.regional.length > 0) {
                var regBailliageLabel =
                    this.filters.bailliage.regional.length > 1
                        ? "Multiple regional bailliages selected"
                        : "Regional bailliage is <b>" + this.filters.bailliage.regional[0] + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: regBailliageLabel,
                    key: "regional_bailliage",
                    values: [this.filters.bailliage.regional_id],
                });
            }

            // Grades filter
            if (this.filters.grade.grades.length > 0) {
                var gradeLabel = this.filters.grade.grades.length > 1 ? "Multiple grades selected" : "Grade is <b>" + this.filters.grade.grades[0] + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: gradeLabel,
                    key: "grade",
                    values: [this.filters.grade.grades],
                });
            }

            // Member types filter
            if (this.filters.memberTypes.selectedMemberTypes.length > 0) {
                var memberTypeLabel =
                    this.filters.memberTypes.selectedMemberTypes.length > 1
                        ? "Multiple member types selected"
                        : "Member type is <b>" + this.filters.memberTypes.selectedMemberTypes[0] + "</b>";

                this.filters.memberTypes.selectedMemberTypes.map((memberType: any) => {
                    switch (memberType) {
                        case "Amateur":
                            this.filters.memberTypes.is_amateur = true;
                            break;
                        case "Pro":
                            this.filters.memberTypes.is_pro = true;
                            break;
                        case "OMGD":
                            this.filters.memberTypes.is_omgd = true;
                            break;
                        case "Jeune sommelier":
                            this.filters.memberTypes.is_young_sommelier = true;
                            break;
                        case "Jeune chef rôtisseur":
                            this.filters.memberTypes.is_young_clerk = true;
                            break;
                    }
                });
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: memberTypeLabel,
                    key: "member_types",
                    values: {
                        is_amateur: this.filters.memberTypes.is_amateur,
                        is_pro: this.filters.memberTypes.is_pro,
                        is_omgd: this.filters.memberTypes.is_omgd,
                        is_young_clerk: this.filters.memberTypes.is_young_clerk,
                        is_young_sommelier: this.filters.memberTypes.is_young_sommelier,
                    },
                });
            }

            // Dues filter
            if (this.filters.dues) {
                var duesLabel = this.filters.dues === "false" ? "Dues are <b>late</b>" : "Dues are <b>up-to-date</b>";
                this.filters.dues === "false" ? (this.filters.dues = false) : (this.filters.dues = true);
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: duesLabel,
                    key: "dues",
                    values: [this.filters.dues],
                });
            }

            // Build filters payload
            this.activeFilters.map((filter: any) => {
                this.payload = {
                    ...this.payload,
                    [filter.key]:
                        Object.prototype.toString.call(filter.values) === "[object Object]"
                            ? filter.values
                            : filter.values.length > 1
                            ? filter.values
                            : filter.values[0],
                };
            });

            if (this.activeFilters.length === 0) {
                ElMessage({
                    type: "info",
                    message: "No filter selected.",
                });
            } else {
                ElMessage({
                    type: "success",
                    message: "Filters applied.",
                });
            }

            this.getFiltersPayload([this.payload, this.activeFilters]);

        },
        getFiltersPayload(e: any) {
            this.tagsKey++;
            this.filtersPayload = e[0];
            this.activeFilters = e[1];
        },
    },
});
